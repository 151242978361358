export const luca = {
    information:{
        title:"LUCA",
        subtitle:'Luca 390-up to 4 People',
        description:'Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical. Due to its widespread use texts.'
    },
   images: [{
        image: require('../../images/projects/luca/pic1.jpg'),
        title: 'Luca',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/luca/pic2.jpg'),
        title: 'L shape sitting area',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/luca/pic3.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/luca/pic4.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/luca/pic5.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    }]
};

export const vista = {
    information:{
        title:"VISTA",
        subtitle:'Vista 390-up to 4 People',
        description:'Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical. Due to its widespread use texts.'
    },
   images: [
    {
        image: require('../../images/projects/vista/pic1.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/vista/pic2.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/vista/pic3.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/vista/pic4.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/vista/pic5.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    }
]};

export const point = {
    information:{
        title:"POINT",
        subtitle:'Point-2 People',
        description:'Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical. Due to its widespread use texts.'
    },
   images: [
    {
        image: require('../../images/projects/point/pic1.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/point/pic2.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/point/pic3.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/point/pic4.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    },
    {
        image: require('../../images/projects/point/pic5.jpg'),
        title: 'New Acropolis Museum',
        description: 'Mattis ex non urna condimentum, eget eleifend diam molestie. Curabitur lorem enimnulla sed, egestas, maximus non nulla sed, egestas venenatis felis',
    }
]}

export const sliderData=[
    {
        title:'NATURAL',
        subtitle:'CREATIVE',
        description:'description1'
    },
    {
        title:'YOUR DREAM',
        subtitle:'WE DESIGN',
        description:'description2'
    },
    {
        title:'MODERN',
        subtitle:'HOUSE',
        description:'description3'
    }
]

export const about={
    firstline:'We provide you with a larger and more comfortable living space with our extraordinary interior design designs.',
    secondline:'We are working with complete equipment and expert teammates in order to provide you with the best service in a 3000 square meter manufacturer area.'
}