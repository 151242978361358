import React from 'react';
import Footer from '../Common/Footer';
import About from './../Segments/About';
import WhoWeAre from './../Segments/WhoWeAre';
import TeamMembers from './../Segments/TeamMembers';
import Blog from './../Segments/Blog';
import Statistics from './../Segments/Statistics';
import Testimonials from './../Segments/Testimonials';
import {luca,vista,point} from "./Constant";
import Slider4 from "../Segments/Slider4";
import Header3 from "../Common/Header3";

class Home extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Slider4 />
                    <About />
                    {/*<LatestProjects />*/}
                    <WhoWeAre project={luca} number={150} />
                    <WhoWeAre project={vista} number={100} />
                    <WhoWeAre project={point} number={50} />
                    <TeamMembers />
                    <Blog colorclass="text-white" />
                    <Statistics />
                    <Testimonials />
                   {/* <ClientsLogo />*/}
                </div>
                <Footer />
            </>
        );
    };
};

export default Home;